<template>
   <v-container fluid>
      <v-row>
         <v-col cols="12">
            <v-card class="" elevation="" max-width="">
               <v-card-title class="justify-center"> MODIFICAR PRODUCTO </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <v-col cols="12">
                        <p>
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }">Inicio</router-link>
                           /
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'lentesContactoPanel' }">Productos</router-link>
                           /
                           <router-link
                              exact=""
                              style="text-decoration: none"
                              :to="{
                                 name: 'lentesContactoEditarPanel',
                                 params: { idproducto: $route.params.idproducto },
                              }"
                              >Editar</router-link
                           >
                        </p>
                     </v-col>
                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12 text-center" v-show="!mostrarContenido">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>
                  </v-row>

                  <v-form @submit.prevent="modificar()" v-show="mostrarContenido" :disabled="processForm">
                     <v-row>
                        <v-col cols="12">
                           <v-alert type="error" v-model="alertError" dismissible ref="mensajeError" id="mensajeError">
                              <ul v-for="(errors, index) in listErrors" :key="index">
                                 <li v-text="errors[0]"></li>
                              </ul>
                           </v-alert>
                        </v-col>


                        <v-col cols="12" md="8">
                           <v-text-field dense label="Nombre(*)" v-model="producto.nombre"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                           <v-text-field dense label="Codigo" v-model="producto.codigo"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <v-text-field dense label="Sub nombre" v-model="producto.sub_nombre" ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="3">
                           <v-text-field type="number" min="0" dense label="Precio lista" v-model="producto.precio_lista">
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="3">
                           <v-text-field type="number" min="0" dense label="Precio" v-model="producto.precio">
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="3">
                           <v-text-field type="number" min="0" dense label="Inventario" v-model="producto.inventario">
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="3">
                           <v-select label="Marca" v-model="producto.idmarca" :items="resources.marcas" item-text="nombre" item-value="idmarca"  dense >
                           </v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-select label="Tipo de lentes" multiple v-model="producto.lc_tipos" :items="resources.lc_tipos" item-text="nombre" item-value="idlc_tipo"  dense >
                           </v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-select label="Colores" multiple v-model="producto.lc_colores" :items="resources.lc_colores" item-text="nombre" item-value="idlc_color" dense >
                           </v-select>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="N° de lentes por caja" v-model="producto.nro_lentes_por_caja" type="number" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Porcentaje de agua" v-model="producto.porcentaje_agua" type="text" dense >
                           </v-text-field>
                        </v-col>
                           
                        <v-col cols="12" md="3" class="pt-0">
                           <span class="my-0">Permite Astigmatismo:</span>
                           <v-switch class="mt-0" hide-details v-model="producto.permite_astigmatismo" :true-value="1" :false-value="0" :label="producto.lentes_contacto_astigmatismo? 'SI' : 'NO'" flat >
                           </v-switch>
                        </v-col>
                           
                        <v-col cols="12" md="3" v-if="producto.permite_astigmatismo">
                           <v-text-field label="Cilindro" v-model="producto.cilindro" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
                           
                        <v-col cols="12" md="3" v-if="producto.permite_astigmatismo">
                           <v-text-field label="Eje" v-model="producto.eje" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="3">
                           <v-text-field label="Curva base" v-model="producto.curva_base" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
      
                        <v-col cols="12" md="6">
                           <v-textarea dense hide-details rows="1" label="Poder" v-model="producto.poder" type="text" ></v-textarea>
                        </v-col>

                        <v-col cols="12" md="3">
                           <v-text-field label="Diámetro" v-model="producto.diametro" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="3">
                           <v-text-field label="Orden Marca" v-model="producto.orden_marca" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
   
                        <v-col cols="12" md="3">
                           <v-text-field label="Orden Tipo" v-model="producto.orden_tipo" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>
                           
                        <v-col cols="12" md="3">
                           <v-text-field label="Esfera" v-model="producto.esfera" type="text" min="0" dense >
                           </v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <v-textarea dense hide-details rows="1" label="Material y porcentaje del contendio" v-model="producto.informacion_adicional" outlined>
                           </v-textarea>
                        </v-col>
                           
                        <v-col cols="12">
                           <v-textarea dense hide-details rows="1" label="Detalle de contenido" v-model="producto.detalle_contenido" outlined></v-textarea>
                        </v-col>

                        <v-col cols="12">
                           <v-textarea dense hide-details label="Descripción" v-model="producto.descripcion" outlined></v-textarea>
                        </v-col>

                        <v-col cols="12">
                           <p class="my-0">Contenido</p>
                           <ckeditor
                              :editor-url="apiUrl('js/ckeditor/ckeditor.js')"
                              v-model="producto.contenido"
                              :config="{
                              filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                              allowedContent: true,
                              }"
                           ></ckeditor>
                        </v-col>
                           
                           
                        <v-col cols="12">
                           <p>Imagenes:</p>
                           <p class="text-center">
                              <v-btn
                                 :loading="loaderImagen"
                                 color="primary"
                                 @click="
                                    $refs.iptImagen.value = '';
                                    $refs.iptImagen.click();
                                 "
                              >
                                 <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGENES
                              </v-btn>
                           </p>
                           <input style="display: none" multiple type="file" ref="iptImagen" @change.prevent="cargarImagenes" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row v-show="imagen.length > 0">
                                 <v-col cols="12">
                                    <v-simple-table dense="" fixed-header="">
                                       <template v-slot:default>
                                          <thead class="text-no-wrap">
                                             <tr>
                                                <th>MOVER</th>
                                                <th>ORDEN</th>
                                                <th>IMAGEN</th>
                                                <th>ORIENTACIÓN</th>
                                                <th>ELIMINAR</th>
                                             </tr>
                                          </thead>
                                          <draggable @update="cambiarOrdenImagen" v-model="imagen" tag="tbody" handle=".cursor-move">
                                             <tr v-for="(img, index) in imagen" :key="index">
                                                <td class="cursor-move">
                                                   <v-icon>mdi-drag-variant</v-icon>
                                                </td>
                                                <td v-text="img.orden"></td>
                                                <td>
                                                   <v-img height="120" width="100" :src="assetAWS('monturas/' + img.nombre)" contain></v-img>
                                                </td>
                                                <td v-if="index == 0">FRONTAL</td>
                                                <td v-else-if="index == 1">PERFIL</td>
                                                <td v-else-if="index == 2">LATERAL</td>
                                                <td v-else-if="index == 3">CENITAL</td>
                                                <td v-else>OTROS</td>
                                                <td>
                                                   <v-btn icon color="red" small @click.prevent="eliminarImagen(img.idproducto_imagen)">
                                                      <v-icon>mdi-trash-can</v-icon>
                                                   </v-btn>
                                                </td>
                                             </tr>
                                          </draggable>
                                       </template>
                                    </v-simple-table>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="12">
                           <p>Imagen Receta:</p>
                           <input style="display: none" type="file" ref="imgReceta" @change.prevent="cargarImgReceta" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row>
                                 <v-col cols="12" class="text-center" v-show="urlImgReceta === ''">
                                    <v-btn
                                       :loading="loaderImgReceta"
                                       color="primary"
                                       @click="
                                          $refs.imgReceta.value = '';
                                          $refs.imgReceta.click();
                                       "
                                    >
                                       <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGEN RECETA
                                    </v-btn>
                                 </v-col>
                                 <v-col cols="12" class="text-center" v-show="urlImgReceta !== ''">
                                    <v-btn icon color="red" small @click.prevent="eliminarImgReceta">
                                       <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                    <v-img :src="urlImgReceta" height="200" contain> </v-img>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="12">
                           <v-select dense item-text="texto" item-value="valor" :items="resources.estado" label="Estado(*)" outlined v-model="producto.estado"></v-select>
                        </v-col>

                        

                        <v-col cols="12" class="text-center">
                           <v-btn class="mr-2" type="submit" :loading="processForm" color="success" large=""> <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR </v-btn>
                           <v-btn :to="{ name: 'lentesContactoPanel' }" type="button" :loading="processForm" color="error" large=""> <v-icon left="" large="">mdi-close</v-icon> CANCELAR </v-btn>
                        </v-col>
                     </v-row>
                  </v-form>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   import draggable from "vuedraggable";

   export default {
      components: {
         draggable,
      },
      data() {
         return {
            resources: {
               marcas: [],
               lc_colores: [],
               lc_tipos: [],
               estado: [
                  {
                     texto: "Habilitado",
                     valor: 1,
                  },
                  {
                     texto: "Inhabilitado",
                     valor: 0,
                  },
               ],
            },
            producto: {
               _method: "PUT",
               idproducto: "",
               codigo: "",
               nombre: "",
               sub_nombre: "",
               descripcion: "",
               contenido: "",
               inventario: "",
               precio: "",
               precio_lista: "",
               estado: 1,

               lc_colores: [],
               lc_tipos: [],
               idmarca: null,
               nro_lentes_por_caja: '',
               porcentaje_agua: '',
               permite_astigmatismo: '',
               cilindro: '',
               eje: '',
               curva_base: '',
               poder: '',
               diametro: '',
               orden_marca: '',
               orden_tipo: '',
               esfera: '',
               informacion_adicional: '',
               detalle_contenido: '',
            },
            urlImgReceta: "",
            loaderImgReceta: false,
            imagen: [],
            mostrarContenido: false,
            processForm: false,
            listErrors: [],
            alertError: false,
            loaderImagen: false,
         };
      },
      computed: {
      },
      methods: {

         cargarImgReceta(event) {
            this.loaderImgReceta = true;

            if (this.urlImgReceta !== "") {
               this.$toasted.error("Ya hay un archivo cargado");
               this.loaderImgReceta = false;
               return;
            }

            if (event.target.files.length > 0) {
               let imagen = event.target.files[0];
               this.urlImgReceta = URL.createObjectURL(imagen);
               this.producto.imagen_receta = imagen;
            }

            this.loaderImgReceta = false;
         },

         eliminarImgReceta() {
            this.urlImgReceta = "";
            this.producto.imagen_receta = "";
         },


         cambiarOrdenImagen() {
            this.loaderImagen = true;

            this.axios({
               method: "POST",
               url: "api/panel/lentes-de-contacto/ordenarArchivos",
               data: {
                  idproducto: this.producto.idproducto,
                  tipoArchivo: "imagen",
                  imagen: JSON.stringify(this.imagen),
               },
            })
               .then((response) => {
                  this.$toasted.success(response.data.mensaje, { icon: "mdi-check-bold", });
                  this.imagen = response.data.imagenes;
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.loaderImagen = false;
               });
         },

         cargarImagenes(event) {
            this.loaderImagen = true;

            if (event.target.files && event.target.files.length > 0) {
               let imagen = event.target.files;
               const form = this.serialize();

               // Object.values(imagen).forEach((value) => {
               //    form.append("imagen[]", value);
               // });
               form.append("imagen[]", ...Object.values(imagen));
               form.append("idproducto", this.producto.idproducto);
               form.append("tipoArchivo", "imagen");

               this.axios({
                  method: "POST",
                  url: "api/panel/lentes-de-contacto/agregarArchivos",
                  data: form,
               })
                  .then((response) => {
                     this.$toasted.success(response.data.mensaje, { icon: "mdi-check-bold", });
                     this.imagen = response.data.imagenes;
                  })
                  .catch((error) => {
                     console.log(error);
                  })
                  .then(() => {
                     this.loaderImagen = false;
                  });
            }
         },

         eliminarImagen(idproducto_imagen) {
            this.loaderImagen = true;
            this.axios({
               method: "POST",
               url: "api/panel/lentes-de-contacto/eliminarAchivo",
               data: {
                  idproducto: this.producto.idproducto,
                  idproducto_imagen: idproducto_imagen,
                  tipoArchivo: "imagen",
               },
            })
               .then((response) => {
                  this.$toasted.success(response.data.mensaje, { icon: "mdi-check-bold", });
                  this.imagen = response.data.imagenes;
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.loaderImagen = false;
               });
         },


         getDatos() {
            this.mostrarContenido = false;

            this.axios({
               method: "GET",
               url: "api/panel/lentes-de-contacto/create",
            })
            .then((response) => {
               const data = response.data;
               

               this.resources.marcas = data.marcas;
               this.resources.lc_colores = data.lc_colores;
               this.resources.lc_tipos = data.lc_tipos;
               
            })
            .catch((error) => {
               console.error(error);
            })


            this.axios({
               method: "GET",
               url: "api/panel/lentes-de-contacto/" + this.$route.params.idproducto + "/edit",
            })
            .then((response) => {
               const data = response.data;
               const producto = data.producto;


               this.producto.idproducto            = producto.idproducto;
               this.producto.codigo                = producto.codigo;
               this.producto.nombre                = producto.nombre;
               this.producto.sub_nombre            = producto.sub_nombre;
               this.producto.descripcion           = producto.descripcion;
               this.producto.contenido             = producto.contenido;
               this.producto.precio                = producto.precio;
               this.producto.precio_lista          = producto.precio_lista;
               this.producto.inventario            = producto.inventario;
               this.producto.estado                = producto.estado;

               this.producto.lc_colores            = producto.lc_colores_pivot.map(ele => ele.idlc_color);
               this.producto.lc_tipos              = producto.lc_tipos_pivot.map(ele => ele.idlc_tipo);
               this.producto.idmarca               = producto.idmarca;
               this.producto.nro_lentes_por_caja   = producto.nro_lentes_por_caja;
               this.producto.porcentaje_agua       = producto.porcentaje_agua;
               this.producto.permite_astigmatismo  = producto.permite_astigmatismo;
               this.producto.cilindro              = producto.cilindro;
               this.producto.eje                   = producto.eje;
               this.producto.curva_base            = producto.curva_base;
               this.producto.poder                 = producto.poder;
               this.producto.diametro              = producto.diametro;
               this.producto.orden_marca           = producto.orden_marca;
               this.producto.orden_tipo            = producto.orden_tipo;
               this.producto.esfera                = producto.esfera;
               this.producto.informacion_adicional = producto.informacion_adicional;
               this.producto.detalle_contenido     = producto.detalle_contenido;

               if (producto.imagen_receta != null) {
                  this.urlImgReceta = this.assetAWS("monturas/" + producto.imagen_receta);
               }


               this.imagen = producto.imagenes;
               this.mostrarContenido = true;
            })
            .catch((error) => {

               console.error(error);
               this.$toasted.error("Ocurrio un error al buscar este registro", { icon: "mdi-close", });
               this.$router.push({ name: "lentesContactoPanel", });

            })
         },

         modificar() {
            this.processForm = true;
            this.alertError = false;

            this.axios({
               method: "POST",
               url: "api/panel/lentes-de-contacto/" + this.producto.idproducto,
               data: this.serialize(this.producto),
            })
               .then((response) => {
               
                  this.$toasted.success(response.data.data, { icon: "mdi-check-bold", });
                  this.$router.push({ name: "lentesContactoPanel", });

               })
               .catch((error) => {
                  let status = error.response.status;
                  if (status === 422) {
                     let arrErrors = error.response.data.errors;
                     this.listErrors = Object.values(arrErrors);
                     this.alertError = true;
                     this.$vuetify.goTo(this.$refs.mensajeError);
                  } else {
                     this.$toasted.error("Ocurrio un error al modificar este registro", {
                        icon: "mdi-close",
                     });
                  }
               })
               .finally(() => {
                  this.processForm = false;
               });
         },
      },
      created() {
         if (!this.validarPermiso("productos.editar")) {
            this.$toasted.error("Usted no tiene permisos para esta sección", { icon: "mdi-close", });
            this.$router.push({ name: "inicioPanel", });
         }

         window.scrollTo(0, 0);
         this.getDatos();
      },
   };
</script>

<style scoped>
   .cursor-move {
      cursor: move;
   }
</style>
